
























































import UIState from "@/modules/outlets/state/ui";
import { t } from "@/i18n";
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onMounted,
  ref,
  watch,
} from "@vue/composition-api";
import { bemBuilder } from "@/v2/util/bem-builder";
import {
  AtomIcon,
  AtomButtonLink,
  AtomButtonSizeEnum,
  AtomLoading,
  OrgHeaderInfo,
  MolGuideLinkArticleEnum,
  MolGuideLink,
  MolModalGuideLink,
  MolModalGuideLinkVideoEnum,
} from "@/v2/new-design-system";
import { IOutletsMainProps } from "@/v2/module/outlet/main/props";
import { fetchOutlets } from "@/v2/repo/fetch-outlets";
import { IFetchOutletsResponse } from "@/v2/repo/fetch-outlets/fetch-outlets";
import { OutletList } from "@/v2/module/outlet/list";
import { updateOutletStatus } from "@/v2/repo/update-outlet-status";
import { Toast } from "@/design-system";
import { OutletStateEnum } from "@/v2/enum";
import { EventEnum } from "@/v2/enum";
import { outletsTrack } from "@/v2/module/outlet/track";
import { deleteOutlet } from "@/v2/repo/delete-outlet";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import globalStore from "@/store";
import { RepoErrorOutletEnum } from "@/v2/repo/repo-error.enum";
import { report } from "@chatfood/bug-reporter";

const css = bemBuilder("outlets-main");

export default defineComponent<IOutletsMainProps>({
  name: "OutletsMain",
  components: {
    AtomIcon,
    AtomLoading,
    AtomButtonLink,
    MolGuideLink,
    MolModalGuideLink,
    OutletList,
    OrgHeaderInfo,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const vm = getCurrentInstance();
    const pageLoading = ref<boolean>(false);
    const outlets = ref<IFetchOutletsResponse["data"]>([]);
    const pagination = ref<IFetchOutletsResponse["paginatorInfo"]>();
    const currentPage = ref<number>(1);
    const itemsPerPage = ref<number>(10);
    const search = ref<string>();
    const { updateLockState } = UIState;

    onMounted(() => {
      updateLockState(props.businessId);
    });

    const getOutletList = async ({
      page = currentPage.value,
      name,
    }: {
      page?: number;
      name?: string;
    }): Promise<void> => {
      const result = await fetchOutlets({
        businessId: props.businessId,
        perPage: itemsPerPage.value,
        page,
        name,
      });

      const { data, paginatorInfo } = result || {};

      outlets.value = data || [];
      pagination.value = paginatorInfo || {};
      currentPage.value = page;
    };

    watch(
      () => props.businessId,
      async () => {
        pageLoading.value = true;

        await getOutletList({
          page: currentPage.value,
        });

        pageLoading.value = false;
      },
      { immediate: true }
    );

    const outletsExist = computed(() => {
      return pagination.value?.total || typeof search.value === "string";
    });

    const subHeading = computed(() =>
      outletsExist.value
        ? t("module.outlets.header.desc")
        : t("module.outlets.header.desc_empty_state")
    );

    const onPageChange = async (page: number): Promise<void> =>
      await getOutletList({ page });

    const onSearchChange = async (name: string): Promise<void> => {
      search.value = name;
      await getOutletList({ name, page: 1 });
    };

    const updateStatus = (id: string, state: string) =>
      outlets.value.map((outlet) =>
        outlet.id === id ? { ...outlet, state } : outlet
      );

    const onStatusUpdate = async (id: string): Promise<void> => {
      const { name = "", state = "" } =
        outlets.value.find((item) => item.id === id) || {};

      try {
        const nextState =
          state === OutletStateEnum.ACTIVE
            ? OutletStateEnum.INACTIVE
            : OutletStateEnum.ACTIVE;

        if ((await updateOutletStatus(id, nextState)) !== true) {
          throw new Error("outletUpdateFailed");
        }

        await updateLockState(props.businessId);
        outlets.value = updateStatus(id, nextState);

        new Toast().create({
          type: "success",
          title: t("label.success"),
          text: t("module.outlets.list.outlet_status_switched"),
        });

        outletsTrack(
          // @ts-ignore
          vm?.proxy.$analytics,
          EventEnum.OUTLET_STATE_UPDATE,
          props.businessId,
          {
            outlet_id: id,
            outlet_name: name,
            state: nextState,
          }
        );
      } catch (error: any) {
        const mapError: { [key: string]: string } = {
          [RepoErrorOutletEnum.ALLOWED_OUTLETS_EXCEEDED]: t(
            "c.outlets.outlet_active_limit"
          ),
          [RepoErrorOutletEnum.MISSING_VALID_ADDRESS]: t(
            "c.outlets.outlet_valid_address"
          ),
          [RepoErrorOutletEnum.MISSING_ACTIVE_ORDERING_MODE]: t(
            "c.outlets.outlet_active_ordering_mode"
          ),
        };

        new Toast().create({
          type: "error",
          title: t("label.oops"),
          text: mapError[error.message] || t("message.general_failure"),
        });

        await updateLockState(props.businessId);
        outlets.value = updateStatus(id, state);
      }
    };

    const onAddOutletClick = () =>
      outletsTrack(
        // @ts-ignore
        vm?.proxy.$analytics,
        EventEnum.OUTLET_START,
        props.businessId
      );

    const onDeleteOutlet = async (
      id: string,
      outletName: string
    ): Promise<void> => {
      try {
        await deleteOutlet(id);

        await updateLockState(props.businessId);

        await getOutletList({ name: search.value, page: 1 });

        refreshOutletsState();

        new Toast().create({
          type: "success",
          title: t("module.outlets.toast.deleted"),
          text: t("module.outlets.toast.is_now_deleted", {
            outletName,
          }),
        });
      } catch (error: any) {
        report(error.message, {
          context: {
            error: error,
          },
          tags: {
            "http.response_code": error.statusCode,
          },
        });

        error.message === RepoErrorOutletEnum.ACTIVE_ORDER_EXIST
          ? generalErrorToast(t("module.outlets.toast.has_active_order"))
          : generalErrorToast();
      }
    };

    const refreshOutletsState = async () => {
      const { getters, dispatch } = globalStore;
      const tenantPermissions = getters["auth/getPermissions"];
      await dispatch("outlets/fetch");
      dispatch("outlets/subscribeToOutletsChannels", tenantPermissions);
    };

    return {
      t,
      css,
      outlets,
      pageLoading,
      outletsExist,
      subHeading,
      pagination,
      currentPage,
      onPageChange,
      onSearchChange,
      onStatusUpdate,
      onAddOutletClick,
      onDeleteOutlet,
      AtomButtonSizeEnum,
      MolGuideLinkArticleEnum,
      MolModalGuideLinkVideoEnum,
    };
  },
});
