










































import { t } from "@/i18n";
import { computed, defineComponent, PropType } from "@vue/composition-api";
import { bemBuilder } from "@/v2/util/bem-builder";
import {
  AtomText,
  AtomSwitcher,
  AtomTextTypeEnum,
  MolInfoLock,
  AtomTextColorEnum,
  AtomSwitcherSizeEnum,
} from "@/v2/new-design-system";
import { OutletStateEnum } from "@/v2/enum/outlet-state.enum";

const css = bemBuilder("outlet-status-cell");

export default defineComponent({
  name: "OutletStatusCell",
  components: { AtomText, AtomSwitcher, MolInfoLock },
  props: {
    status: {
      type: String as PropType<OutletStateEnum>,
      required: true,
    },
    hasAddress: {
      type: Boolean,
      required: true,
    },
    hasPickup: {
      type: Boolean,
      required: true,
    },
    hasDelivery: {
      type: Boolean,
      required: true,
    },
    hasDineIn: {
      type: Boolean,
      required: true,
    },
    stepsTotalCount: {
      type: Number,
      default: 5,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isLocked: {
      type: Boolean,
      required: true,
    },
    onStatusUpdate: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  setup(props) {
    const isDraft = computed(() => props.status === OutletStateEnum.DRAFT);
    const isActive = computed(() => props.status === OutletStateEnum.ACTIVE);
    const label = computed(() => {
      const translates: { [key: string]: string } = {
        [OutletStateEnum.ACTIVE]: t("module.outlets.outlet_states.active"),
        [OutletStateEnum.DRAFT]: t("module.outlets.outlet_states.draft"),
        [OutletStateEnum.INACTIVE]: t("module.outlets.outlet_states.inactive"),
      };

      return translates[props.status];
    });
    const completedStepsCount = computed(() =>
      [
        props.hasAddress,
        props.hasPickup,
        props.hasDelivery,
        props.hasDineIn,
      ].reduce((result, rule) => (rule ? result + 1 : result), 1)
    );

    return {
      t,
      css,
      label,
      isDraft,
      isActive,
      completedStepsCount,
      AtomTextTypeEnum,
      OutletStateEnum,
      AtomTextColorEnum,
      AtomSwitcherSizeEnum,
    };
  },
});
