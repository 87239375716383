import { graphql } from "../graphql";
import mutation from "./mutation.gql";
import { RepoErrorEnum, RepoErrorOutletEnum } from "../repo-error.enum";
import { FetchResult } from "@apollo/client";
import { graphqlExtractInternalErrors } from "@/v2/util/graphql-extract-internal-errors";

export async function deleteOutlet(outletId: string): Promise<boolean> {
  let response: FetchResult<any, Record<string, any>, Record<string, any>>;
  try {
    response = await graphql.mutate({
      mutation,
      variables: {
        outletId,
      },
    });
  } catch {
    throw Error(RepoErrorEnum.NETWORK_ERROR);
  }

  if (response.errors) {
    const internalError = graphqlExtractInternalErrors(response);

    const isOutletWithOngoingOrder = response.errors[0].message;

    if (
      [isOutletWithOngoingOrder, internalError?.[0]].includes(
        "Outlet has an active order"
      )
    ) {
      throw Error(RepoErrorOutletEnum.ACTIVE_ORDER_EXIST);
    }
  }

  return response.data.deleteOutlet;
}
