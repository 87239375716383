
































































































































import { find } from "ramda";
import { t, tc } from "@/i18n";
import { computed, defineComponent, PropType, ref } from "@vue/composition-api";
import UIState from "@/modules/outlets/state/ui";
import {
  AtomText,
  AtomTextTagEnum,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomStatusMarker,
  MolTableActionLink,
  MolPagination,
  MolSearchBox,
  OrgTable,
  IOrgTableProps,
  AtomStatusMarkerColorEnum,
  MolTableAction,
  MolTableActionTypeEnum,
  MolModalConfirm,
} from "@/v2/new-design-system";
import {
  OrderingServiceOutletEnum,
  OrderingServiceStateEnum,
  OutletStateEnum,
} from "@/v2/enum";
import { bemBuilder } from "@/v2/util/bem-builder";
import { debounce } from "@/v2/util/debounce";
import { OutletStatusCell } from "@/v2/module/outlet/status-cell";
import { IOutlet, IOrderingServices, IOutletListProps } from "./props";
import { useRouter } from "@/router";

const css = bemBuilder("outlets-list");

const tableColumnsConfig: IOrgTableProps["columns"] = {
  state: {
    header: () => t("module.outlets.list.headers.status"),
    show: true,
    sortable: false,
    sortDirection: "NONE",
    width: 160,
  },
  name: {
    header: () => t("module.outlets.list.headers.name"),
    show: true,
    sortable: false,
    sortDirection: "NONE",
    width: 300,
  },
  deliveryState: {
    header: () => t("module.outlets.list.headers.delivery"),
    show: true,
    sortable: false,
    sortDirection: "NONE",
    width: 150,
  },
  pickupState: {
    header: () => t("module.outlets.list.headers.pickup"),
    show: true,
    sortable: false,
    sortDirection: "NONE",
    width: 150,
  },
  dineInState: {
    header: () => t("module.outlets.list.headers.dining"),
    show: true,
    sortable: false,
    sortDirection: "NONE",
    width: 180,
  },
};

export default defineComponent({
  name: "OutletList",
  components: {
    AtomText,
    AtomStatusMarker,
    MolTableActionLink,
    MolPagination,
    MolSearchBox,
    OrgTable,
    OutletStatusCell,
    MolTableAction,
    MolModalConfirm,
  },
  props: {
    outlets: {
      type: Array as PropType<IOutletListProps["outlets"]>,
      required: true,
      default: Array,
    },
    pagination: {
      type: Object as PropType<IOutletListProps["pagination"]>,
      required: true,
      default: Object,
    },
    onPageChange: {
      type: Function as PropType<(page: Number) => Promise<void>>,
      required: true,
    },
    onSearchChange: {
      type: Function as PropType<(name: String) => Promise<void>>,
      required: true,
    },
    onStatusUpdate: {
      type: Function as PropType<IOutletListProps["onStatusUpdate"]>,
      required: true,
    },
    onDeleteOutlet: {
      type: Function as PropType<IOutletListProps["onDeleteOutlet"]>,
      required: true,
    },
  },
  setup(props) {
    const isLoading = ref<boolean>(false);
    const searchFilter = ref<string>("");
    const rowInProcess = ref<boolean>(false);
    const totalOutlets = computed(() => props.pagination?.total);
    const isEmptyList = computed(() => props.outlets?.length === 0);

    const { isLocked } = UIState;

    const deleteModalData = ref<{
      id: string;
      name: string;
    } | null>(null);

    const handleSearchChange = debounce(async (name: string) => {
      isLoading.value = true;
      await props.onSearchChange(name);
      isLoading.value = false;
    }, 500);

    const handlePageChange = async (page: number) => {
      isLoading.value = true;
      await props.onPageChange(page);
      isLoading.value = false;
    };

    const getOrderingModeState = (
      orderingServices: IOutlet["orderingServices"],
      orderingMode: OrderingServiceOutletEnum
    ): string => {
      const record: IOrderingServices | undefined = find(
        (orderingService: IOrderingServices) =>
          orderingService.slug === orderingMode,
        orderingServices
      );
      return record?.state ?? OrderingServiceStateEnum.DISABLED;
    };

    async function updateStatus(rowId: string) {
      rowInProcess.value = true;
      await props.onStatusUpdate(rowId);
      rowInProcess.value = false;
    }

    const template: {
      [key: string]: { label: () => string; color: string };
    } = {
      [OrderingServiceStateEnum.ENABLED]: {
        label: () => t("module.outlets.ordering_mode_states.enabled"),
        color: AtomStatusMarkerColorEnum.MINT,
      },
      [OrderingServiceStateEnum.DISABLED]: {
        label: () => t("module.outlets.ordering_mode_states.disabled"),
        color: AtomStatusMarkerColorEnum.CORAL,
      },
      [OrderingServiceStateEnum.PAUSED]: {
        label: () => t("module.outlets.ordering_mode_states.paused"),
        color: AtomStatusMarkerColorEnum.CANARY,
      },
    };

    const isEnabled = (status: string) =>
      status === OrderingServiceStateEnum.ENABLED;

    const outletList = computed(() =>
      props.outlets.map((outlet) => {
        const orderingServices = outlet.orderingServices;

        const delivery = getOrderingModeState(
          orderingServices,
          OrderingServiceOutletEnum.DELIVERY
        );
        const pickup = getOrderingModeState(
          orderingServices,
          OrderingServiceOutletEnum.PICKUP
        );
        const dineIn = getOrderingModeState(
          orderingServices,
          OrderingServiceOutletEnum.DINE_IN
        );

        let deliveryState = template[delivery];
        let pickupState = template[pickup];
        let dineInState = template[dineIn];

        if (outlet.state === OutletStateEnum.INACTIVE) {
          const color = AtomStatusMarkerColorEnum.RAINY;

          deliveryState = { ...deliveryState, color };
          pickupState = { ...pickupState, color };
          dineInState = { ...dineInState, color };
        }

        return {
          ...outlet,
          uniqueId: outlet.id,
          deliveryState: {
            isEnabled: isEnabled(delivery),
            ...deliveryState,
          },
          pickupState: {
            isEnabled: isEnabled(pickup),
            ...pickupState,
          },
          dineInState: {
            isEnabled: isEnabled(dineIn),
            ...dineInState,
          },
        };
      })
    );

    const colorStateHandler = (
      state: OutletStateEnum,
      defaultColor: AtomTextColorEnum
    ): AtomTextColorEnum =>
      state === OutletStateEnum.INACTIVE
        ? AtomTextColorEnum.RAINY
        : defaultColor;

    const handleDelete = async () => {
      if (!deleteModalData.value) return Promise.resolve();

      const { id, name } = deleteModalData.value;
      await props.onDeleteOutlet(id, name);

      deleteModalData.value = null;
    };

    const router = useRouter();
    const goToViewOutlet = (outletId: string, businessId: string) => {
      router.push({
        name: "outlets.edit",
        params: {
          businessId,
          outletId,
        },
      });
    };

    return {
      t,
      tc,
      css,
      tableColumnsConfig,
      searchFilter,
      outletList,
      totalOutlets,
      isLoading,
      isEmptyList,
      isLocked,
      updateStatus,
      rowInProcess,
      deleteModalData,
      handleSearchChange,
      handlePageChange,
      handleDelete,
      colorStateHandler,
      goToViewOutlet,
      AtomTextTagEnum,
      AtomTextColorEnum,
      AtomTextTypeEnum,
      OutletStateEnum,
      MolTableActionTypeEnum,
    };
  },
});
